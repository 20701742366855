import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-duration"
export default class extends Controller {
  static targets = ["durationSelect"];

  form = this.element;

  submit(e) {
    if (!confirm("Are you sure you want to change your reading duration?")) {
      e.preventDefault();
      e.stopPropagation();

      this.form.reset();
    }
  }

  changeSelection() {
    this.form.requestSubmit();
  }
}
