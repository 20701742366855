import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFormElement> {
  private timer: NodeJS.Timer = null

  timeoutInSeconds = 10

  connect() {
    const url = this.element.action;
    const method = this.element.method;
    const data = new FormData(this.element);

    this.timer = setTimeout(() => {
      fetch(url, {
        body: data,
        method: method,
      }).then((response) => {
        // if the form is recorded before the request is made, the request will never be made
        this.element.remove();

        if (response.ok) {
          // recorded successfully
        } else {
          // failed to record
        }
      }, (_error) => {
        // failed to record
      });
    }, this.timeoutInSeconds * 1000);
  }

  disconnect() {
    clearTimeout(this.timer);
  }
}
