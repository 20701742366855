import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-plan"
export default class extends Controller {
  modal = document.querySelector('.change-plan-modal');

  click() {
    if (!this.modal) return;

    this.modal.classList.add('show');
  }
}
