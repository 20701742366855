import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-plan-modal"
export default class extends Controller {
  static targets = ['newPlanName', 'form'];

  modal = document.querySelector('.change-plan-modal');

  close() {
    this.modal.classList.remove('show');
  }

  selectPlan(event) {
    const confirmed = confirm(`Are you sure you want to change to plan ${event.target.dataset.planName}?`);

    if (confirmed) {
      this.newPlanNameTarget.value = event.target.dataset.planName;
      this.formTarget.submit();
    }
  }
}
